.table-tab {
  ul {
    margin-bottom: 24px;
    li {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      button.nav-link {
        font-weight: $font-weight-bold;
        padding: 10px;
        line-height: normal;
        color: $secondary;
        background-color: $gray-400;
        &.active {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
  .nav-pills {
    @include respond-below(custom575) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
  }
}
.badge {
  &.badge-bgdanger {
    background-color: rgba(255, 0, 0, 0.06);
    color: $danger;
    padding: 5px 10px;
  }
}
button.swal2-styled.swal2-confirm {
  background-color: $primary;
  &:hover {
    background-color: $secondary;
  }
}
table {
  tbody {
    td {
      &.action-table-data {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        a {
          height: 31px;
          width: 31px;
          border: 1px solid rgba(145, 158, 171, 0.3);
          background-color: $white;
          border-radius: 8px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          justify-content: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          padding: 8px;
          .feather-edit {
            color: $blue;
            width: 14px;
            height: 14px;
          }
          .action-edit {
            color: $text-color;
            width: 14px;
            height: 14px;
          }
          .feather-trash-2 {
            color: $danger;
            width: 14px;
            height: 14px;
          }
          .feather-eye {
            width: 14px;
            height: 14px;
          }
          .action-download {
            color: $blue;
            width: 14px;
            height: 14px;
          }
          .action-eye {
            color: $secondary;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    .productimgname {
      min-width: 200px;
      a {
        &.stock-img.product-img {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          justify-content: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          margin-right: 8px;
          img {
            width: 50px;
            height: 50px;
            max-width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
  .edit-delete-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: left !important;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    height: 50px;
    a {
      padding: 0;
    }
  }
  .edit-delete-action.data-view {
    min-height: 52px;
  }
  .edit-delete-action.data-row {
    height: auto;
  }
  .edit-delete-action.data {
    height: 44px;
  }
}
.table-top-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  &.low-stock-top-head {
    @include respond-below(custom575) {
      flex-wrap: wrap;
      justify-content: start;
    }
    li {
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  li {
    margin-right: 10px;
    flex-shrink: 0;
    &:last-child {
      margin-right: 0;
    }
    #collapse-header {
      margin-right: 0;
      @include respond-below(custom991) {
        display: none;
      }
    }
    a {
      height: 38px;
      width: 38px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      border: 1px solid $gray-300;
      background: $white;
      border-radius: 8px;
      padding: 6px;
      font-size: 18px;
      svg {
        height: 20px;
      }
      &:hover {
        background: #e3e3e3;
      }
      label {
        margin-right: 10px;
      }
      &.btn-secondary {
        background-color: $secondary;
        color: $white;
        padding: 10px;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        &:hover {
          background-color: $primary;
        }
        svg {
          width: 16px;
          margin-right: 5px;
          color: $white;
        }
        @include respond-below(custom767) {
          padding: 5px;
          font-size: $font-size-base;
        }
      }
      .action-edit.sales-action {
        color: $blue;
      }
    }
    .status-toggle {
      height: 38px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      border: 1px solid $gray-100;
      background: $white;
      border-radius: 8px;
      padding: 6px 10px;
      label {
        margin-right: 10px;
      }
    }
  }
}
.custom-modal-header {
  background: $body-bg;
  padding: 24px;
  .page-title {
    h4 {
      font-size: $font-size-18;
      font-weight: $font-weight-bold;
      color: $secondary;
    }
  }
}
.custom-modal-body {
  padding: 24px;
  .input-blocks {
    margin-right: 0;
  }
  label {
    font-size: $font-size-15;
    font-weight: $font-weight-normal;
  }
}
.status-label {
  color: #212b36;
}
.modal-footer-btn {
  margin-top: 40px;
  text-align: right;
  a {
    min-width: auto;
    @include respond-below(custom575) {
      padding: 10px 10px;
    }
  }
}
.status-toggle.modal-status label {
  height: 15px;
  width: 32px;
  background-color: #b9b9c3;
  margin-bottom: 0;
}
.status-toggle.modal-status label::after {
  top: 7px;
}
td {
  p {
    &.description-para {
      max-width: 400px;
      white-space: break-spaces;
    }
  }
}
.form-sort {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 35px;
  }
  span.select2-container.select2-container--default.select2-container--open {
    z-index: 99;
  }
}
#filter_inputs {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 35px;
  }
  input {
    padding-left: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span.select2-container.select2-container--default.select2-container--open {
    z-index: 98;
  }
}
#filter_inputs1 {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 35px;
  }
  input {
    padding-left: 35px;
  }
  span.select2-container.select2-container--default.select2-container--open {
    z-index: 98;
  }
}
#filter_inputs2 {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 35px;
  }
  input {
    padding-left: 35px;
  }
  span.select2-container.select2-container--default.select2-container--open {
    z-index: 98;
  }
}
.summer-description-box {
  .card .card-header {
    padding: 8px;
    border-bottom: 1px solid rgba(145, 158, 171, 0.32);
  }
  .note-editor {
    border: 1px solid rgba(145, 158, 171, 0.32);
  }
  .note-editable {
    height: 150px !important;
  }
  .note-resizebar {
    display: none;
  }
}
.table tbody {
  td {
    a {
      &.view-note {
        color: $blue;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.userimgname {
  .product-img {
    display: inherit;
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }
}
.modal-body-table {
  background-color: #f6f7f8;
  padding: 24px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.product-quantity {
  border: 1px solid $gray-400;
  background-color: $body-bg;
  width: 105px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 8px;
  input {
    width: 35px;
    border: 0;
    background-color: $body-bg;
    text-align: center;
    height: 35px !important;
  }
  span {
    color: $secondary;
    font-size: 0;
    cursor: pointer;
    .plus-circle-qty {
      width: 20px;
      height: 20px;
    }
    .feather-search-qty {
      width: 20px;
      height: 20px;
    }
  }
}
.modal-dialog.stock-adjust-modal {
  max-width: 800px;
  .dataTables_length,
  .dataTables_info,
  .dataTables_paginate {
    display: none;
  }
}
.input-blocks.search-form {
  input {
    padding-left: 30px;
  }
  svg {
    position: absolute;
    left: 10px;
    top: 37px;
    width: 15px;
  }
}
.badge badge-linedanger {
  background-color: rgba(255, 0, 0, 0.06);
  color: $danger;
  border-radius: 8px;
  padding: 4px 10px;
  font-size: $font-size-base;
}
.input-groupicon.calender-input input {
  padding-left: 27px;
}
span {
  &.customer-toggle {
    color: $secondary;
  }
}
span {
  &.unlimited-text {
    color: $text-color;
    margin-top: 5px;
    display: inline-block;
  }
}
.modal-title-head {
  h6 {
    font-size: $font-size-16;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    svg {
      width: 15px;
      color: $primary;
      margin-right: 5px;
    }
  }
}
#phone,
#phone2,
#phone3 {
  padding-left: 50px;
}
.total-employees {
  background-color: $body-bg;
  padding: 10px 8px;
  margin-right: 24px;
  border-radius: 8px;
  h6 {
    font-size: $font-size-base;
    color: $secondary;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      margin-right: 5px;
    }
    span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      font-size: $font-size-10;
      width: 18px;
      height: 18px;
      background-color: $danger;
      color: $white;
      border-radius: 50%;
      margin-left: 5px;
    }
  }
}
.search-path.search-path-new {
  .btn-list,
  .btn-grid {
    width: 36px;
    height: 36px;
    background-color: $body-bg;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: $secondary;
    margin-right: 15px;
    border-radius: 8px;
    &.active {
      background-color: $primary;
      color: $white;
      &:hover {
        background-color: $secondary;
        color: $white;
      }
    }
    svg {
      width: 18px;
      height: 18px;
    }
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  .form-sort {
    @include respond-below(custom575) {
      margin-top: 0;
    }
  }
}
.layout-box {
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: $primary;
  color: $white;
  border-radius: 8px;
  &:hover {
    background-color: $secondary;
    color: $white;
  }
}
td {
  span {
    &.emp-team {
      display: block;
      line-height: normal;
    }
  }
}
.badges-inactive {
  border: 1px solid $danger;
  color: $danger;
  padding: 2px 10px;
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  border-radius: 8px;
}
.success-email-send.modal-body {
  padding: 24px;
  span {
    svg {
      width: 40px;
      height: 40px;
      color: $success;
      margin-bottom: 20px;
    }
  }
  h4 {
    font-size: $font-size-20;
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
  }
  p {
    font-size: $font-size-15;
  }
  a.btn {
    padding: 10px 20px;
  }
}
.layout-drop-item {
  width: 350px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 20px;
  box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
  background: $white;
  border-radius: 8px;
  position: absolute;
  right: 0;
  margin-top: 10px;
  z-index: 101;
  display: none;
  .drop-item-head {
    border-bottom: 1px solid rgba(145, 158, 171, 0.3);
    @include margin-padding(0 0 20px, 0 0 20px);
    h5 {
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      margin-bottom: 10px;
    }
    p {
      font-size: $font-size-base;
    }
  }
  ul {
    li {
      margin-bottom: 20px;
      span {
        display: flex;
        align-items: center;
        svg {
          width: 16px;
          margin-right: 5px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.layout-hide-box {
  position: relative;
}
.layout-show-box {
  .layout-drop-item {
    display: block;
  }
}
.table-top {
  &.table-top-new {
    @include respond-below(custom767) {
      flex-direction: column;
      align-items: self-start;
    }
    .search-path {
      margin-left: 0;
      @include respond-below(custom767) {
        margin-top: 24px;
      }
      @include respond-below(custom575) {
        flex-direction: column;
        align-items: self-start;
      }
      .form-sort {
        @include respond-below(custom575) {
          margin-top: 20px;
        }
      }
    }
    .search-set {
      @include respond-below(custom767) {
        flex-direction: column;
        align-items: flex-start;
        .total-employees {
          margin: 0 0 20px 0;
        }
      }
    }
  }
}
.badge-applied {
  background-color: $purple;
  padding: 2px 10px;
  color: $white;
  border-radius: 5px;
}
.badge-approved {
  background-color: $success;
  padding: 2px 10px;
  color: $white;
  border-radius: 5px;
}
.badge-reject {
  background-color: $danger;
  padding: 2px 10px;
  color: $white;
  border-radius: 5px;
}
.table tbody tr td a.reason-for-reject {
  color: $blue;
  font-weight: $font-weight-semibold;
}
.note-editor.note-frame.card {
  margin-bottom: 0;
}
table tbody td.action-table-data a.cancel-circle {
  border: 0;
  padding: 0;
  svg {
    color: $primary;
  }
  &:hover {
    background-color: transparent;
    svg {
      color: $danger !important;
    }
  }
}
.apply-leave {
  background-color: $body-bg;
  border-radius: 8px;
  .leave-apply {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: 10px;
    @include respond-below(custom575) {
      flex-direction: column;
      align-items: flex-start;
    }
    .leave-date {
      margin-right: 70px;
      span {
        color: $text-color;
      }
      p {
        color: $secondary;
      }
      @include respond-below(custom575) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    .leave-time {
      width: 175px;
    }
  }
}
.input-blocks {
  .bootstrap-tagsinput {
    width: 100%;
    padding: 0 0.75rem;
    box-shadow: none;
    line-height: 40px;
    .tag {
      background-color: $gray-300;
      color: $secondary;
      padding: 3px 10px;
      border-radius: 4px;
      position: relative;
      z-index: 1;
      .remove {
        &::after {
          color: $text-color;
        }
      }
    }
    input {
      position: static;
      width: auto;
      border: none;
    }
  }
  span.tag-text {
    color: $text-color;
    font-size: $font-size-13;
  }
}
.table tbody tr td .sales-dropdown-list a {
  padding: 10px 20px;
}
.no-pagination {
  .pagination {
    display: none;
  }
}
#collapse-header.active {
  background-color: $primary;
  border-color: unset;
  svg {
    color: $white;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
  &:hover {
    svg {
      color: $white;
    }
  }
}
.edit-delete-action {
  a {
    &:hover {
      background-color: rgb(40, 142, 199);
      border-color: rgb(40, 142, 199);
      svg {
        color: $white !important;
      }
    }
    &.confirm-text {
      &:hover {
        background-color: $danger;
        border-color: $danger;
      }
    }
    &.edit-icon {
      &:hover {
        background-color: $secondary;
        border-color: $secondary;
      }
    }
  }
}
.modal {
  table.table {
    thead > tr > th.sorting:before {
      display: none !important;
    }
    thead > tr > th.sorting:after {
      display: none !important;
    }
  }
}
.profit-head {
  .input-blocks {
    .info-img {
      color: $secondary;
    }
    input {
      padding-left: 35px;
    }
  }
}
.profile-pic-upload .profile-pic.brand-pic {
  width: 110px;
  height: 105px;
  position: relative;
  .remove-photo {
    position: absolute;
    right: 8px;
    top: 10px;
    background: $danger;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    svg {
      color: $white;
      width: 14px;
      height: 14px;
    }
  }
}
.sales-details-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 20px;
  @include respond-below(custom767) {
    flex-direction: column;
    align-items: start;
  }
  .details-item {
    @include respond-below(custom767) {
      margin-bottom: 20px;
    }
    h6 {
      margin-bottom: 15px;
      font-size: $font-size-15;
      color: $secondary;
    }
    p {
      font-size: $font-size-base;
      color: $text-color;
    }
    h5 {
      color: $success;
      font-size: $font-size-15;
      span {
        display: block;
        color: $text-color;
      }
    }
  }
}
.order-text {
  color: $secondary;
  font-weight: $font-weight-semibold;
  font-size: $font-size-base;
  margin-bottom: 15px;
}
.profile-head-action {
  .badge-linesuccess {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: 3px 10px;
    height: 24px;
  }
  .badge-linedanger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: 3px 10px;
    height: 24px;
  }
}
