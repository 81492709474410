.page-wrapper-three {
  margin-left: 0;
  padding-top: 0;
  .content {
    background: $body-bg;
    border-radius: 20px;
    margin-top: -82px;
  }
}
.header-three {
  position: relative;
  .header-left {
    border-right: 0 !important;
    @include margin-padding(null, 25px);
  }
}
.logo-white {
  display: none !important;
}
.sidebar.side-three.horizontal-sidebar {
  position: relative;
  top: 0;
  width: 100%;
  height: 150px;
  background: #1b2950;
  border-top: 1px solid $gray-400;
  box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  z-index: unset;
  .sidebar-menu {
    @include margin-padding(null, 0 15px);
    & > ul {
      & > li {
        margin-bottom: 0;
        margin-right: 20px;
        & > a {
          @include margin-padding(null, 22px 15px);
          img,
          svg {
            filter: brightness(0) invert(1);
            margin-right: 0;
          }
          &:hover {
            background: transparent;
            span {
              color: $primary !important;
            }
            img,
            svg {
              filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg)
                brightness(105%) contrast(112%);
            }
          }
          img {
            top: 0 !important;
            width: auto;
          }
          &::before {
            content: '';
            @include position(absolute, 50%, -10px, null, null);
            width: 1px;
            height: 15px;
            background: #6b7a99;
            transform: translateY(-50%);
          }
        }
        & > a {
          span {
            color: $white !important;
          }
        }
        a {
          font-weight: $font-weight-medium;
          font-size: $font-size-15;
          color: $white;
          img {
            position: relative;
            top: 3px;
            width: 18px;
            margin-right: 7px;
            filter: brightness(0) invert(1);
          }
          svg {
            width: 18px;
            margin-right: 7px;
          }
          &:hover {
            img,
            svg {
              filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg)
                brightness(105%) contrast(112%);
            }
          }
        }
        .menu-arrow {
          position: initial;
          transform: rotate(90deg);
          margin-left: 15px;
        }
        ul {
          position: absolute;
          width: 250px;
          height: auto;
          z-index: 1;
          background: #1b2950;
          li {
            &.submenu {
              a {
                padding: 8px 15px !important;
                color: $white;
                .menu-arrow {
                  float: right;
                  margin-right: 15px;
                }
                i {
                  margin-right: 7px;
                }
                img {
                  position: relative;
                  top: 3px;
                  width: 18px;
                  margin-right: 7px;
                }
                svg {
                  width: 18px;
                  margin-right: 7px;
                }
                &:hover {
                  color: $primary;
                  img {
                    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg)
                      brightness(105%) contrast(112%);
                  }
                }
              }
              & ul {
                box-shadow: none;
              }
              ul {
                position: relative;
                @include margin-padding(null, 0);
                li {
                  a {
                    padding: 8px 10px 8px 40px !important;
                    color: $white;
                  }
                }
              }
            }
            a {
              padding: 8px 15px !important;
              &::after {
                content: none !important;
              }
            }
          }
        }
        @include respond-below(custom1199) {
          margin-right: 0;
        }
        &:last-child {
          & > a {
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}
@include respond-above(custom992) {
  .hide-sidebar {
    display: none;
  }
}
@include respond-below(custom991) {
  .header-three {
    position: fixed;
  }
  .horizontal-sidebar {
    display: none;
  }
  .page-wrapper-three {
    padding-top: 60px;
    .content {
      margin-top: 0;
    }
  }
}
