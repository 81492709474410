.sidebar,
.sidebars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: linear-gradient(178deg, rgb(1, 106, 13) -0.89%, rgb(4, 179, 66) 99.46%);
  border-right: 1px solid transparent;
  margin-top: 0;
  z-index: 10;
  @include transition(all 0.5s ease);
  border-right: 1px solid $gray-400;
  width: 260px;

  // @include respond-below(custom991) {
  //   margin-left: -575px;
  //   @include transition(all 0.4s ease);
  //   z-index: 1041;
  //   background: $white;
  // }

  @include respond-below(custom575) {
    width: 100%;
  }

  .slimScrollDiv {
    background: $white;
    border-right: 1px solid transparent;

    @include respond-below(custom575) {
      width: 100% !important;
    }
  }

  .sidebar-menu {
    @include margin-padding(null, 32px 16px);
  }

  .sidebar-menu {
    > ul {
      > li {
        // padding: 15px;

        &.active {
          a {
            color: $white;
          }

          svg {
            color: #06aeff;
          }
        }

        svg {
          min-width: 16px;
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        > a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          @include margin-padding(null, 10px 15px);
          position: relative;
          color: $primary-text;

          svg {
            color: #637381;
          }

          img {
            width: 18px;
            color: #637381;
          }

          &:hover {
            background: rgba(6, 174, 255, 0.08);
            color: #06aeff;
            border-radius: 5px;

            img {
              filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
            }

            span {
              color: #06aeff;
            }

            svg {
              color: #06aeff;
            }
          }

          &.active {
            background: rgba(6, 174, 255, 0.08);
            color: #06aeff;
            border-radius: 5px;

            svg {
              color: $white;
            }

            img {
              filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
            }

            span {
              color: #06aeff;
            }

            .menu-arrow {
              background: #ffeddc;

              &::before {
                border-color: #06aeff;
              }
            }
          }

          span {
            font-size: $font-size-15;
            font-weight: $font-weight-medium;
            color: $gray-600;
          }
        }

        &.active {
          a {
            background: rgba(6, 174, 255, 0.08);
            border-radius: 5px;

            img {
              filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
            }

            span {
              color: #06aeff;
            }
          }
        }

        &.submenu {
          ul {
            border-bottom: 1px solid rgba(222, 226, 230, 0.5);
            @include margin-padding(null, 10px 0);
            display: block;

            li {
              a {
                font-weight: $font-weight-medium;
                font-size: $font-size-base;
                color: $secondary;
                position: relative;
                display: block;
                @include margin-padding(null, 8px 8px 8px 40px);

                &.active {
                  color: $secondary;
                }

                &::after {
                  content: '';
                  background: rgba(50, 71, 92, 0.38);
                  width: 8px;
                  height: 8px;
                  border: 2px solid $white;
                  @include rounded(50px);
                  @include position(absolute, 16px, null, null, 16px);
                }

                &:hover {
                  color: $primary;

                  &:after {
                    background: #06aeff;
                    border: 2px solid #fdb;
                  }
                }
              }
            }
          }
        }

        &.submenu-open {
          ul {
            // border-bottom: 1px solid $gray-300;
            @include margin-padding(null, 0 0 14px 0);
            display: block;

            li {
              div.multi-level-menu-item {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                font-weight: $font-weight-medium;
                font-size: $font-size-15;
                color: #67748e;
                position: relative;
                width: 100%;
                @include margin-padding(null, 0px 10px 0px 18px);

                span {
                  white-space: nowrap;
                  font-size: $font-size-base;
                  color: $text-color;
                }

                &.active {
                  color: $primary;

                  a {
                    span {
                      color: $primary;
                    }
                  }

                  &:after {
                    background: #06aeff;
                    border: 2px solid #fdb;
                  }
                }

                &:hover {
                  color: $primary;

                  &:after {
                    background: #06aeff;
                    border: 2px solid #fdb;
                  }

                  span {
                    color: $primary;
                  }
                }
              }
            }

            > li {
              &.active div.multi-level-menu-item {
                background: rgba(6, 174, 255, 0.08);
                border-radius: 5px;
                color: #06aeff;

                span {
                  color: $primary;
                }
              }
            }
          }

          .submenu-hdr {
            //font-weight: $font-weight-medium;
            font-size: $font-size-base;
            color: #1b2950;
            line-height: 24px;
          }

          &:first-child {
            h6 {
              margin-top: 0;
            }
          }

          .submenu {
            ul {
              border: 0;
              padding-top: 0;
              padding-bottom: 0;
            }
          }
        }
      }

      li {
        &.active {
          a {
            color: #06aeff;
          }

          svg {
            color: #06aeff;
          }
        }

        .submenu > {
          a {
            &.active {
              background: rgba(6, 174, 255, 0.08);
              color: #06aeff;
              border-radius: 5px;

              span {
                color: #06aeff;
              }

              .menu-arrow {
                background: #ffeddc;

                &::before {
                  border-color: #06aeff;
                }
              }
            }
          }
        }

        .submenu .submenu-two > {
          a {
            &.active {
              background: transparent;
            }
          }
        }
      }
    }

    .submenu-open {
      &:last-child {
        margin-bottom: 0;
        position: relative !important;
        ul {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      .submenu {
        &.submenu-two {
          ul {
            li {
              a {
                margin-left: 45px;
              }
            }
          }

          &.submenu-three {
            ul {
              li {
                a {
                  margin-left: 65px;
                }
              }
            }
          }
        }

        &.submenu-level-1 {
          ul {
            li {
              a {
                margin-left: 50px;
                padding-bottom: 4px;
                font-size: $font-size-base;
                color: $sub-title;

                &::after {
                  content: '';
                  background: rgba(50, 71, 92, 0.38);
                  width: 8px;
                  height: 8px;
                  border: 2px solid $white;
                  @include rounded(50px);
                  @include position(absolute, 16px, null, null, 0);
                }

                &:hover {
                  color: $primary;

                  &:after {
                    background: $primary;
                    border: 2px solid #fdb;
                  }
                }
              }
            }
          }
        }

        ul {
          li {
            a {
              margin-left: 50px;
              padding-bottom: 4px;
              font-size: $font-size-base;
              color: $sub-title;

              &::after {
                content: '';
                background: rgba(50, 71, 92, 0.38);
                width: 8px;
                height: 8px;
                border: 2px solid $white;
                @include rounded(50px);
                @include position(absolute, 16px, null, null, 0);
              }

              &:hover {
                color: $primary;

                &:after {
                  background: $primary;
                  border: 2px solid #fdb;
                }
              }
            }
          }
        }
      }
    }

    .menu-arrow {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      text-rendering: auto;
      line-height: 40px;
      font-size: $font-size-18;
      line-height: 16px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      @include position(absolute, 14px, 23px, null, null);
      @include transform(translate(0, 0));
      @include transition(all 0.2s ease);

      &::before {
        height: 5px;
        width: 5px;
        border-top: 0;
        content: '';
        border-left: 0;
        border-right: 1px solid $white;
        border-bottom: 1px solid $white;
        transform: rotate(-45deg);
        position: absolute;
        left: 5.7px;
        top: 5.7px;
      }

      &.inside-submenu {
        right: 40px;

        &.inside-submenu-two {
          right: 60px;
        }
      }
    }

    &.subdrop {
      .menu-arrow {
        @include transform(rotate(90deg));
        background: #ffeddc;
      }
    }
  }

  .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    @include margin-padding(null, 10px);
    color: $secondary;
    position: relative;

    &.active {
      color: $primary;
      background: transparent;
      position: relative;

      &:after {
        content: '';
        border-width: 10px;
        border-color: transparent $primary transparent transparent;
        border-style: solid;
        @include position(absolute, 36px, 0, null, null);
      }
    }
  }

  .sidebar-left {
    width: 100px;
    @include box-shadow(null, 5px, 0px, 15px, null, rgba(0, 0, 0, 0.05));
  }

  .sidebar-right {
    width: 250px;
    height: 100%;
    @include transition(all 0.2s ease);
    display: none;

    @include respond-below(custom991) {
      width: calc(100% - 70px);
    }

    .slimScrollDiv {
      width: 250px !important;
      overflow: visible !important;

      @include respond-below(custom991) {
        width: 100% !important;
      }
    }

    .tab-content {
      @include margin-padding(null, 20px 20px 40px);

      @include respond-below(custom991) {
        @include margin-padding(null, 10px);
      }
    }

    p {
      font-size: $font-size-10;
      color: $gray-500;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        &.active {
          a {
            background: linear-gradient(46.62deg, $primary 0%, $violet 93.64%);
            color: $white;
          }
        }

        a {
          font-weight: $font-weight-normal;
          font-size: $font-size-base;
          color: $secondary;

          &.active {
            color: $primary;
          }
        }
      }
    }

    .menu-arrow {
      -webkit-transition: -webkit-transform 0.15s;
      -o-transition: -o-transform 0.15s;
      transition: transform 0.15s;
      display: inline-block;
      font-family: 'Font Awesome 5 Free';
      text-rendering: auto;
      line-height: 40px;
      font-size: $font-size-18;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 18px;
      top: 0px;
      position: relative;
      font-weight: $font-weight-bold;
      @include transform(translate(0, 0));

      &:before {
        content: '\f105';
      }
    }
  }

  .sub-menu a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
  }

  li {
    a.subdrop {
      display: inline-block !important;
      .menu-arrow {
        @include transform(rotate(90deg));
      }
    }
  }

  .nav-items {
    .nav-link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      @include rounded(0);
      border-bottom: 1px solid $gray-400;
      @include margin-padding(null, 20px 0);
      @include transition(all 0.4s ease);

      &:hover {
        box-shadow: 0 0 0 150px $violet inset;
        color: $white;
      }

      span {
        margin-top: 15px;
        font-weight: $font-weight-medium;
      }
    }
  }

  .sidemenu {
    a {
      width: 100%;
      font-weight: $font-weight-medium;
      display: block;
      @include rounded(5px);
      @include margin-padding(0 0 10px, 10px);

      &:hover {
        background: linear-gradient(46.62deg, $primary 0%, $violet 93.64%);
        color: $white;

        svg {
          color: $white;
        }
      }

      svg {
        stroke-width: 1px;
      }
    }
  }
}

.header-left {
  #toggle_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: absolute;
    top: 14px;
    opacity: 1;
    background: $primary;
    right: -13px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: $white;
    cursor: pointer;

    svg {
      transform: rotate(180deg);
    }

    &:hover {
      background: $primary-hover;
    }

    @include respond-below(custom991) {
      display: none;
    }
  }

  &.active svg {
    transform: rotate(0) !important;
  }
}

@include respond-above(custom991) {
  .mini-sidebar {
    .page-wrapper {
      // margin-left: 40px;
    }

    .header-left {
      #toggle_btn {
        opacity: 0;

        &:after {
          border: 0;
        }
      }
    }

    &.expand-menu {
      .header-left {
        #toggle_btn {
          opacity: 1;
        }
      }
    }

    .sidebar-right {
      display: none;
    }

    .sidebar {
      .sidebar-menu {
        ul {
          li {
            a {
              span {
                display: block;
              }
            }

            .submenu-hdr {
              display: none;
            }

            .menu-item {
              width: 24px;
              height: 24px;
            }

            .icon.submenu-hdr {
              display: block !important;
              width: 18px !important;
              height: 18px !important;
            }

            svg {
              margin-right: 0;
            }
          }
        }
      }
    }

    .sidebar {
      width: 80px;
    }
  }

  .mini-sidebar:not(.expand-menu) {
    .button-menu-item {
      padding-left: 0px;
      padding-right: 0px;
      justify-content: center;
    }
  }

  // .expand-menu{
  //     .sidebar-right {
  //         display: block;
  //         @include transition(all 0.2s ease);
  //     }
  //     .sidebar {
  //         // width: 260px;
  //         .sidebar-menu{
  //            ul{
  //                > li{
  //                     > a {
  //                         @include margin-padding(null, 10px 15px);
  //                         span{
  //                             display:inline-block;
  //                             line-height: 0;
  //                         }
  //                     }
  //                     svg {
  //                         margin-right:10px;
  //                     }
  //                 }
  //             }
  //             .submenu-hdr {
  //                 display:block;
  //             }
  //         }
  //     }
  //     .header-left{
  //         width: 260px;
  //     }
  // }
  #toggle_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    float: left;
    margin-left: 15px;
  }
}

body.mini-sidebar {
  .sidebar {
    .sidebar-menu > ul > li.submenu-open ul li a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      font-weight: $font-weight-medium;
      font-size: $font-size-15;
      color: $primary-text;
      position: relative;
      width: 100%;
      // padding: 10px;
    }
  }

  &.expand-menu {
    .sidebar {
      .sidebar-menu > ul > li.submenu-open ul li a {
        justify-content: left;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        padding-left: 18px;
        padding-right: 10px;
      }
    }
  }
}

.sidebarrightmenu {
  .sidebar-right {
    display: block;
  }
}

.slide-nav {
  .sidebar {
    margin-left: 0;
  }
}

.sidebar-overlay {
  display: none;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  @include position(fixed, 60px, null, null, 0);

  &.opened {
    display: block;
  }
}

.nosidebar {
  .sidebar {
    left: -350px;
  }

  .page-wrapper {
    margin: 0;
  }

  #toggle_btn {
    svg {
      @include transform(rotate(180deg));
    }
  }
}

.mini-sidebar {
  .active.subdrop ~ ul {
    display: none !important;
  }
  .submenu {
    display: none;
  }
  [data-author='atom'] > span {
    display: none;
  }
}

.mini-sidebar.expand-menu {
  .active.subdrop ~ ul {
    display: block !important;
  }
  .submenu {
    display: block;
  }
  [data-author='atom'] > span {
    display: block;
  }
}

.active.subdrop ~ ul {
  display: block !important;
}

.sidebar {
  &.horizontal-sidebar {
    &.sidebar-one {
      .submenu {
        ul {
          background: $body-bg;
        }
      }
    }
  }
}

.sidebar {
  color: #fff !important;
  .sidebar-menu {
    ul {
      li {
        div.multi-level-menu-item {
          color: #fff !important;
          span {
            color: #fff !important;
          }
        }
      }
    }
  }
  .sidebar-menu > ul > li.submenu-open {
    position: relative;
    .submenu-hdr {
      color: #fff !important;
    }
  }

  .menu-item {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .button-menu-item {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 12px 10px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #2f2f2f2e;
      color: #fff;
    }
  }

  .menu-focus {
    background-color: #2f2f2f2e;
    color: #fff;
  }
}

// sidebar horizontal
[data-layout-style='horizontal'] .main-wrapper {
  & > div:first-child,
  & .sidebar-wrapper,
  & .sidebar {
    width: 0;
  }

  & .powered-by {
    display: none;
  }

  #sidebar {
    & .header-left {
      display: none;
    }
  }

  #sidebar-menu {
    padding: 0 !important;
    font-family: $font-family-primary;

    .menu-focus {
      background-color: $secondary-50;
      span {
        color: $primary !important;
      }
    }

    & > ul {
      position: fixed;
      top: 66px;
      left: 0;
      width: 100vw;
      display: flex;
      height: 68px;
      background: $white;
      border-top: 1px solid $gray-400;
      box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
      z-index: 20;
      padding: 8px;
      align-items: center;
      @include margin-padding(null, 0 15px);

      & > li {
        border-radius: 8px;
        margin-bottom: 0;
        margin-right: 20px;
        padding: 0 15px;

        & div.multi-level-menu-item,
        &.submenu-hdr,
        h6,
        span {
          color: #121212 !important;
          font-weight: 400;
        }

        &.submenu {
          ul {
            li button.active {
              color: $primary;
            }
          }
        }

        &.submenu-open {
          button.button-menu-item {
            border-radius: unset;
            border-bottom: 2px solid transparent;
          }
          &:has(.menu-focus) {
            // background-color: $secondary-50;
            & > .button-menu-item {
              border-bottom: 2px solid $primary;
            }

            h6 {
              color: $primary !important;
            }

            img,
            svg {
              filter: brightness(0) saturate(100%) invert(25%) sepia(20%) saturate(4000%) hue-rotate(85deg)
                brightness(95%) contrast(90%);
            }
            .menu-arrow {
              &::before {
                position: initial;
                border-right-color: $primary;
                border-bottom-color: $primary;
              }
            }
          }
        }

        & > button {
          @include margin-padding(null, 15px 0);
          background: transparent;
          font-weight: $font-weight-medium;
          font-size: $font-size-15;
          color: $gray-600;

          &:hover {
            background: transparent;
            span,
            h6 {
              color: $primary !important;
            }

            img,
            svg {
              filter: brightness(0) saturate(100%) invert(25%) sepia(20%) saturate(4000%) hue-rotate(85deg)
                brightness(95%) contrast(90%);
            }
            .menu-arrow {
              &::before {
                position: initial;
                border-right-color: $primary;
                border-bottom-color: $primary;
              }
            }
          }

          img,
          svg {
            width: 18px;
            margin-right: 7px;
            filter: brightness(0) saturate(100%) invert(8%) sepia(5%) saturate(800%) hue-rotate(0deg) brightness(85%)
              contrast(90%);

            @include respond-below(custom1199) {
              margin-right: 5px;
            }
          }

          &.active {
            background: transparent;
          }
        }

        & .mega-menu {
          padding: 0 !important;
          box-shadow:
            0 10px 15px -3px rgb(0 0 0 / 0.1),
            0 4px 6px -4px rgb(0 0 0 / 0.1);
          .button-menu-item {
            border-radius: 8px !important;
            &:hover {
              background-color: $secondary-50 !important;
            }
          }
          li {
            margin: 0 0.5rem !important;
            &:first-child {
              margin-top: 0.5rem !important;
            }
            &:last-child {
              margin-bottom: 0.5rem !important;
            }
          }
        }

        .menu-arrow {
          position: initial;
          transform: rotate(90deg);

          &::before {
            position: initial;
            border-right: 1.5px solid #5b6670;
            border-bottom: 1.5px solid #5b6670;
          }
        }

        & ul {
          position: absolute;
          width: 250px;
          background-color: $white;
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
          border-radius: 8px;
          max-height: 0;
          transition: max-height 0.3s ease-out;
          overflow: hidden;
          padding: 0;

          li {
            &.submenu {
              a {
                padding: 8px 15px !important;

                .menu-arrow {
                  float: right;
                  margin-right: 15px;
                }

                img,
                svg {
                  width: 18px;
                  margin-right: 7px;
                }
              }

              ul {
                box-shadow: 0px 4px 14px rgb(198 198 198 / 25%);
                li a {
                  padding: 8px 10px 8px 40px !important;
                }
              }

              &.submenu-two {
                ul li a {
                  margin-left: 10px;
                }

                &.submenu-three {
                  ul li a {
                    margin-left: 20px;
                  }
                }
              }
            }

            a {
              padding: 8px 15px !important;
              &::after {
                content: none !important;
              }
            }
          }
          &.open {
            max-height: 500px;
          }
        }

        @include respond-below(custom1199) {
          margin-right: 0;
        }
      }
    }
  }

  #more-menu {
    &:has(.menu-focus) > button {
      background-color: rgb(210 239 210) !important;
    }
    & > ul {
      top: 100%;
      right: 0;
      box-shadow:
        0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1) !important;

      & > li {
        border-radius: 8px;
        margin-bottom: 0;
        padding: 0 15px;

        & div.multi-level-menu-item,
        &.submenu-hdr,
        h6,
        span {
          color: #121212 !important;
          font-weight: 400;
        }

        &.submenu {
          ul {
            li button.active {
              color: $primary;
            }
          }
        }

        &.submenu-open {
          ul {
            position: relative !important;
          }

          button.button-menu-item {
            border-radius: unset;
            border-bottom: 2px solid transparent;
          }
          &:has(.menu-focus) {
            & > .button-menu-item {
              border-bottom: 2px solid $primary;
            }

            h6 {
              color: $primary !important;
            }

            img,
            svg {
              filter: brightness(0) saturate(100%) invert(25%) sepia(20%) saturate(4000%) hue-rotate(85deg)
                brightness(95%) contrast(90%);
            }
            .menu-arrow {
              &::before {
                position: initial;
                border-right-color: $primary;
                border-bottom-color: $primary;
              }
            }
          }
        }

        & > button {
          @include margin-padding(null, 15px 0);
          background: transparent;
          font-weight: $font-weight-medium;
          font-size: $font-size-15;
          color: $gray-600;

          &:hover {
            background: transparent;
            span,
            h6 {
              color: $primary !important;
            }

            img,
            svg {
              filter: brightness(0) saturate(100%) invert(25%) sepia(20%) saturate(4000%) hue-rotate(85deg)
                brightness(95%) contrast(90%);
            }
            .menu-arrow {
              &::before {
                position: initial;
                border-right-color: $primary;
                border-bottom-color: $primary;
              }
            }
          }

          img,
          svg {
            width: 18px;
            margin-right: 7px;
            filter: brightness(0) saturate(100%) invert(8%) sepia(5%) saturate(800%) hue-rotate(0deg) brightness(85%)
              contrast(90%);

            @include respond-below(custom1199) {
              margin-right: 5px;
            }
          }

          &.active {
            background: transparent;
          }
        }

        & .mega-menu {
          width: auto !important;
          box-shadow: none !important;
          li {
            margin: 0 !important;
            &:first-child {
              margin-top: 0 !important;
            }
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
          .button-menu-item {
            border-radius: 8px !important;
            &:hover {
              background-color: $secondary-50 !important;
            }
          }
        }

        .menu-arrow {
          position: initial;
          transform: rotate(90deg);

          &::before {
            position: initial;
            border-right: 1.5px solid #5b6670;
            border-bottom: 1.5px solid #5b6670;
          }
        }

        @include respond-below(custom1199) {
          margin-right: 0;
        }
      }
    }
  }
}
