.icons-list {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  li {
    display: block;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    font-size: $font-size-18;
    @include rounded(50px);
    border: 1px solid #e6ebf1;
    color: $primary;
    @include margin-padding(5px, null);
    @include box-shadow(null, 0, 2px, 3px, null, rgb(215, 197, 255));
  }
}
