.page-header-breadcrumb {
  .breadcrumb-item + .breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, '\ea65');
    font-family: 'tabler-icons' !important;
  }
}
[dir='rtl'] {
  .page-header-breadcrumb {
    .breadcrumb-item + .breadcrumb-item::before {
      content: var(--bs-breadcrumb-divider, '\ea64');
      font-family: 'tabler-icons' !important;
    }
  }
}
.breadcrumb {
  .breadcrumb-item {
    a {
      color: $text-muted;
    }

    &.active {
      color: $primary;
    }
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  color: $text-muted;
  opacity: 0.4;
}
.breadcrumb-arrow {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $text-muted;
    content: '\f101';
    font-family: $font-awesome !important;
  }
}
.breadcrumb-pipe {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $text-muted;
    content: '\f061';
    font-family: $font-awesome !important;
  }
}
.breadcrumb-line {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $text-muted;
    content: '-';
  }
}
.breadcrumb-dot {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $text-muted;
    content: '•';
  }
}
.breadcrumb-divide {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $text-muted;
    content: '\f054';
    font-family: $font-awesome !important;
  }
}
.breadcrumb-separatorless {
  .breadcrumb-item + .breadcrumb-item::before {
    content: '';
    display: none;
  }
}
.breadcrumb-colored {
  padding: 12px 15px;
  border-radius: $border-radius;
  li {
    a {
      color: $white;
    }
  }
  .breadcrumb-item {
    a {
      color: $white;
    }
    &.active {
      color: $white;
      opacity: 0.8;
    }
    & + .breadcrumb-item {
      a {
        color: $white;
      }
      &::before {
        color: $white;
      }
    }
  }
}
.embedded-breadcrumb:before {
  opacity: 0.7;
}
[data-theme-mode='dark'] {
  .embedded-breadcrumb:before {
    filter: invert(1);
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  button {
    padding: 8px;
    background-color: #dee2e6;
    box-sizing: border-box;
    border: 1px solid #dee2e6;
    &:focus {
      border: 1px solid $primary;
    }
  }
}

.custom-search-input {
  .tag-title {
    background-color: $primary;
  }
  .popup-filter {
    .filter-icon,
    .layer-icon {
      color: $primary;
    }
  }
  .custom-input-result {
    color: $primary !important;
  }
  &:focus-within {
    border-color: $primary;
  }
}
