.custom-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease;
}

.custom-radio:checked {
  border-color: $primary; /* Change this to your desired color */
}

.custom-radio:checked::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: $primary; /* Change this to your desired color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-radio-label {
  cursor: pointer;
  margin-left: 8px;
}
