.modal-barcode {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      @include rounded(7px);
      width: 25%;
      margin-bottom: 10px;
      padding: 0 5px;
      @include respond-below(custom991) {
        width: 50%;
      }
      a {
        border: 1px solid $gray-400;
        @include rounded(10px);
        display: block;
        padding: 10px;
        text-align: center;
      }
    }
  }
}

.modal {
  .modal-title {
    font-weight: $font-weight-bold;
    @include respond-below(custom991) {
      font-size: $font-size-16;
    }
  }
  .logo-lg {
    img {
      width: 150px;
    }
  }
  .close,
  .btn-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: $danger-700;
    opacity: 1;
    font-size: $font-size-12;
    width: 22px;
    height: 22px;
    border: 0;
    font-weight: $font-weight-bold;
    background-color: transparent;
    @include rounded(50px);
    &:hover {
      background-color: transparent;
      color: $white;
    }
  }
  .modal-footer {
    border: 0;
    justify-content: flex-start;
    .btn {
      min-width: 130px;
      @include margin-padding(null, 12px);
      @include respond-below(custom991) {
        min-width: auto;
        @include margin-padding(null, 7px 12px);
      }
    }
    .btn-print {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      border: 1px solid $primary;
      color: $primary;
      img {
        margin-left: 10px;
      }
      &:hover {
        background: $primary;
        color: $white;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    .btn-secondary {
      background: $secondary;
      &:hover {
        @include box-shadow(inset, 0, 0px, 0px, 100px, $white);
        color: $secondary !important;
      }
    }
  }

  .modal-dialog.right-slider-modal {
    margin: 0;
    margin-left: auto;
    max-width: 1102px;
  }

  .modal-dialog.add-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    max-width: 1192px;
  }
  .modal-dialog.sales-details-modal {
    max-width: 500px;
  }
  .modal-dialog.edit-sales-modal {
    max-width: 1192px;
  }
  .edit-add.card.edit-expense {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .note-editor.note-frame.card {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
}
.offcanvas.offcanvas-end {
  width: auto !important;
}
.offcanvas.offcanvas-end.em-payrol-add {
  max-width: 1192px !important;
}

.modal {
  &.animated .modal-dialog {
    transform: translate(0, 0);
  }
  &.effect-scale {
    .modal-dialog {
      transform: scale(0.7);
      opacity: 0;
      transition: all 0.3s;
    }
    &.show .modal-dialog {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.effect-slide-in-right {
    .modal-dialog {
      transform: translateX(20%);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    }
    &.show .modal-dialog {
      transform: translateX(0);
      opacity: 1;
    }
  }
  &.effect-slide-in-bottom {
    .modal-dialog {
      transform: translateY(20%);
      opacity: 0;
      transition: all 0.3s;
    }
    &.show .modal-dialog {
      transform: translateY(0);
      opacity: 1;
    }
  }
  &.effect-newspaper {
    .modal-dialog {
      transform: scale(0) rotate(720deg);
      opacity: 0;
    }
    &.show ~ .modal-backdrop,
    .modal-dialog {
      transition: all 0.5s;
    }
    &.show .modal-dialog {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
  }
  &.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;
    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: translateZ(600px) rotateX(20deg);
      opacity: 0;
    }
    &.show .modal-dialog {
      transition: all 0.3s ease-in;
      transform: translateZ(0px) rotateX(0deg);
      opacity: 1;
    }
  }
  &.effect-flip-horizontal {
    perspective: 1300px;
    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: rotateY(-70deg);
      transition: all 0.3s;
      opacity: 0;
    }
    &.show .modal-dialog {
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
  &.effect-flip-vertical {
    perspective: 1300px;
    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: rotateX(-70deg);
      transition: all 0.3s;
      opacity: 0;
    }
    &.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
  &.effect-super-scaled {
    .modal-dialog {
      transform: scale(2);
      opacity: 0;
      transition: all 0.3s;
    }
    &.show .modal-dialog {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.effect-sign {
    perspective: 1300px;
    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: rotateX(-60deg);
      transform-origin: 50% 0;
      opacity: 0;
      transition: all 0.3s;
    }
    &.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
  &.effect-rotate-bottom {
    perspective: 1300px;
    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: translateY(100%) rotateX(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
    &.show .modal-dialog {
      transform: translateY(0%) rotateX(0deg);
      opacity: 1;
    }
  }
  &.effect-rotate-left {
    perspective: 1300px;
    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: translateZ(100px) translateX(-30%) rotateY(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s;
    }
    &.show .modal-dialog {
      transform: translateZ(0px) translateX(0%) rotateY(0deg);
      opacity: 1;
    }
  }
}
