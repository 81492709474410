body {
  font-family: $font-family-primary;
  font-size: $font-size-14;
  color: $text-color;
  line-height: 1.5;
  background-color: $background-form;
}
::selection {
  background: $primary;
  color: $white;
  text-shadow: none;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: inherit;
}
* {
  outline: none;
}
button {
  @include transition(all 0.5s ease);
  &:focus {
    box-shadow: none !important;
  }
}
a {
  color: $primary;
  cursor: pointer;
  text-decoration: none;
  @include transition(all 0.5s ease);
  &:hover {
    color: $primary-hover;
    @include transition(all 0.5s ease);
  }
  &:focus {
    outline: 0;
  }
}
p {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
strong {
  font-weight: $font-weight-bold;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: $title-color;
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  margin-bottom: 0;
}
ol,
ul {
  list-style: none;
  margin-block-end: 0;
  padding: 0;
}
ul {
  list-style: none;
  &.list-disc {
    list-style: disc;
    padding-left: 15px;
    ul {
      list-style-type: circle;
    }
  }
  &.list-decimal {
    list-style: decimal;
    padding-left: 15px;
    ul {
      list-style-type: decimal;
    }
  }
  &.list-icon {
    list-style: none;
    padding-left: 0;
    > li {
      padding-left: 1.875rem;
      counter-increment: li;
      i,
      .feather-icon,
      .svg-icon {
        display: inline-block;
        height: 20px;
        width: 20px;
        text-align: center;
        margin-left: -1.25rem;
        position: relative;
        left: -10px;
        padding: 1px;
        top: -1px;
      }
      i {
        font-size: $font-size-20;
        top: 5px;
      }
    }
  }
  .btn {
    &-primary {
      &:not(:disabled):not(.disabled) {
        &:active,
        &.active {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.button-primary {
  border-radius: 4px;
  background-color: $primary-button;
  padding: 10px 32px;
  color: $white;
  font-weight: 500;
  height: 40px;
  min-width: 92px;
  font-size: 14px;
  letter-spacing: -0.5%;
  @include respond-below(custom575) {
    padding: 8px;
    height: fit-content;
    min-width: fit-content;
    font-size: 12px;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.button-secondary {
  border: 1px solid;
  border-radius: 4px;
  border-color: $secondary;
  background-color: $white;
  padding: 10px 32px;
  color: $secondary;
  font-weight: 500;
  height: 40px;
  min-width: 92px;
  font-size: 14px;
  letter-spacing: -0.5%;
  @include respond-below(custom768) {
    padding: 8px;
    height: fit-content;
    font-size: 12px;
  }
}

.button-delete {
  border: 1px solid;
  border-radius: 4px;
  border-color: #a50202;
  background-color: $white;
  padding: 10px 32px;
  color: #a50202;
  font-weight: 500;
  height: 40px;
  min-width: 92px;
  font-size: 14px;
  letter-spacing: -0.5%;
  @include respond-below(custom768) {
    padding: 8px;
    height: fit-content;
    font-size: 12px;
  }
}
// .button-primary:hover {
//   background-color: #005b11;
//   color: $white;
// }

// .button-secondary:hover {
//   background-color: $primary-button;
//   color: $white;
// }

.field {
  border-radius: 10px !important;
  border: 1px solid $secondary-50 !important;
  min-height: 44px !important;
  width: 100% !important;
  // max-width: 527px !important;
  background-color: $secondary-50 !important;
  padding: 12px 12px 12px 8px !important;
  font-weight: 400 !important;
  color: $text-color !important;
  font-size: 14px !important;
  min-width: max-content;
  gap: 8px;
  opacity: 1;
  cursor: pointer !important;
  field-sizing: content;
  line-height: 1.5;
  scrollbar-width: none;
  resize: none;
  min-width: unset;
  &[readonly] {
    cursor: not-allowed !important;
    border: 1px solid transparent !important;
    color: $placeholder-color !important;

    opacity: 1;
  }
}

.field-warning {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: max-content !important;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  border-radius: 8px;
  background: #f5f7fa;
  margin-bottom: 4px;
}

.field-in-tree {
  padding: 0 8px !important;
}

.field:focus-within,
.field:focus,
.field-in-tree:focus,
.field-in-tree:focus-within {
  border: solid 1px $primary !important;
  box-shadow: none;
}

.field > input::placeholder,
.field::placeholder,
.field-in-tree > input::placeholder,
.field-in-tree::placeholder {
  color: $placeholder-color !important;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.container {
  max-width: 100% !important;
  padding: 0 !important;
}

thead {
  display: table-header-group;
  direction: ltr;
}

tfoot {
  display: table-footer-group;
  direction: ltr;
}

tbody {
  display: table-row-group;
  direction: ltr;
}

tr {
  display: table-row;
  direction: ltr;
}

td,
th {
  display: table-cell;
  min-width: 1% !important;
}

table {
  direction: ltr;
}
