:root {
  --primary: #0B701D; /* Default primary color */
}
.btn,
.customizer-links,
.btn-custom,
#toggle_btn,
.sidebar-header {
  background-color: #fff !important;
  border: 1px solid #e5e5e5 !important;
}

.active-color {
  color: var(--primary) !important;
}

.active-color:hover {
  color: var(--primary) !important;
}

.custom-active-span:hover {
  color: var(--primary) !important;
}

.sidebar-content,
body,
.custom-scroll {
  scrollbar-color: var(--primary) var(--background) !important;
}

.sidebar-content::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track {
  background: var(--background);
}

.sidebar-content::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  border: 2px solid var(--background);
}

.nav-link.active {
  color: var(--primary) !important;
  border-bottom-color: #fff !important;
}

.nav-link:hover {
  color: var(--primary) !important;
}
a {
  text-decoration: none !important;
}

#link-custom a:hover,
.sidebar-menu > ul > li.submenu-open ul li a:hover {
  color: var(--primary) !important;
}

.theme-mode .layout-wrap .check:checked + label img {
  border-color: var(--primary) !important;
}

.submenu a::after:hover,
.theme-name::after {
  background-color: var(--primary) !important;
  color: var(--primary) !important;
}

.theme-mode .layout-wrap .check:checked + label .theme-name::after {
  background-color: var(--primary) !important;
}

.sl-box-7 .sl-loader .sl-loader-items .sl-cube,
.sidebar-menu .submenu-open .submenu ul li a:hover:after,
.confirm-delete-breadcrumb {
  background-color: var(--primary) !important;
}

/* .custom-search-input .custom-input-result,
.custom-search-input .popup-filter .filter-icon,
.custom-search-input .popup-filter .layer-icon {
  color: var(--primary) !important;
} */
.sl-ekvalayzer .sl-loader-items .sl-parent-rectangular .sl-rectangular {
  background-color: var(--primary) !important;
  box-shadow: 0 0 10px 2px var(--primary) !important;
}
