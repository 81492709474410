div#global-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  background: $white;
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100vh;

  .whirly-loader:not(:required) {
    animation: whirly-loader 1.25s linear infinite;
    background: transparent;
    box-shadow:
      0 26px 0 6px $primary,
      0.90971px 26.05079px 0 5.93333px $primary,
      1.82297px 26.06967px 0 5.86667px $primary,
      2.73865px 26.05647px 0 5.8px $primary,
      3.65561px 26.01104px 0 5.73333px $primary,
      4.57274px 25.93327px 0 5.66667px $primary,
      5.48887px 25.8231px 0 5.6px $primary,
      6.40287px 25.68049px 0 5.53333px $primary,
      7.31358px 25.50548px 0 5.46667px $primary,
      8.21985px 25.2981px 0 5.4px $primary,
      9.12054px 25.05847px 0 5.33333px $primary,
      10.01448px 24.78672px 0 5.26667px $primary,
      10.90054px 24.48302px 0 5.2px $primary,
      11.77757px 24.1476px 0 5.13333px $primary,
      12.64443px 23.78072px 0 5.06667px $primary,
      13.5px 23.38269px 0 5px $primary,
      14.34315px 22.95384px 0 4.93333px $primary,
      15.17277px 22.49455px 0 4.86667px $primary,
      15.98776px 22.00526px 0 4.8px $primary,
      16.78704px 21.48643px 0 4.73333px $primary,
      17.56953px 20.93855px 0 4.66667px $primary,
      18.33418px 20.36217px 0 4.6px $primary,
      19.07995px 19.75787px 0 4.53333px $primary,
      19.80582px 19.12626px 0 4.46667px $primary,
      20.5108px 18.468px 0 4.4px $primary,
      21.1939px 17.78379px 0 4.33333px $primary,
      21.85416px 17.07434px 0 4.26667px $primary,
      22.49067px 16.34043px 0 4.2px $primary,
      23.10251px 15.58284px 0 4.13333px $primary,
      23.68881px 14.80241px 0 4.06667px $primary,
      24.24871px 14px 0 4px $primary,
      24.7814px 13.1765px 0 3.93333px $primary,
      25.28607px 12.33284px 0 3.86667px $primary,
      25.76198px 11.46997px 0 3.8px $primary,
      26.2084px 10.58888px 0 3.73333px $primary,
      26.62462px 9.69057px 0 3.66667px $primary,
      27.01001px 8.77608px 0 3.6px $primary,
      27.36392px 7.84648px 0 3.53333px $primary,
      27.68577px 6.90284px 0 3.46667px $primary,
      27.97502px 5.94627px 0 3.4px $primary,
      28.23116px 4.97791px 0 3.33333px $primary,
      28.4537px 3.99891px 0 3.26667px $primary,
      28.64223px 3.01042px 0 3.2px $primary,
      28.79635px 2.01364px 0 3.13333px $primary,
      28.91571px 1.00976px 0 3.06667px $primary,
      29px 0 0 3px $primary,
      29.04896px -1.01441px 0 2.93333px $primary,
      29.06237px -2.03224px 0 2.86667px $primary,
      29.04004px -3.05223px 0 2.8px $primary,
      28.98185px -4.07313px 0 2.73333px $primary,
      28.88769px -5.09368px 0 2.66667px $primary,
      28.75754px -6.1126px 0 2.6px $primary,
      28.59138px -7.12863px 0 2.53333px $primary,
      28.38926px -8.14049px 0 2.46667px $primary,
      28.15127px -9.1469px 0 2.4px $primary,
      27.87755px -10.1466px 0 2.33333px $primary,
      27.56827px -11.1383px 0 2.26667px $primary,
      27.22365px -12.12075px 0 2.2px $primary,
      26.84398px -13.09268px 0 2.13333px $primary,
      26.42956px -14.05285px 0 2.06667px $primary,
      25.98076px -15px 0 2px $primary,
      25.49798px -15.93291px 0 1.93333px $primary,
      24.98167px -16.85035px 0 1.86667px $primary,
      24.43231px -17.75111px 0 1.8px $primary,
      23.85046px -18.63402px 0 1.73333px $primary,
      23.23668px -19.49789px 0 1.66667px $primary,
      22.5916px -20.34157px 0 1.6px $primary,
      21.91589px -21.16393px 0 1.53333px $primary,
      21.21024px -21.96384px 0 1.46667px $primary,
      20.4754px -22.74023px 0 1.4px $primary,
      19.71215px -23.49203px 0 1.33333px $primary,
      18.92133px -24.2182px 0 1.26667px $primary,
      18.10379px -24.91772px 0 1.2px $primary,
      17.26042px -25.58963px 0 1.13333px $primary,
      16.39217px -26.23295px 0 1.06667px $primary,
      15.5px -26.84679px 0 1px $primary,
      14.58492px -27.43024px 0 0.93333px $primary,
      13.64796px -27.98245px 0 0.86667px $primary,
      12.69018px -28.50262px 0 0.8px $primary,
      11.7127px -28.98995px 0 0.73333px $primary,
      10.71663px -29.4437px 0 0.66667px $primary,
      9.70313px -29.86317px 0 0.6px $primary,
      8.67339px -30.2477px 0 0.53333px $primary,
      7.6286px -30.59666px 0 0.46667px $primary,
      6.57001px -30.90946px 0 0.4px $primary,
      5.49886px -31.18558px 0 0.33333px $primary,
      4.41643px -31.42451px 0 0.26667px $primary,
      3.32401px -31.6258px 0 0.2px $primary,
      2.22291px -31.78904px 0 0.13333px $primary,
      1.11446px -31.91388px 0 0.06667px $primary,
      0 -32px 0 0 $primary,
      -1.11911px -32.04713px 0 -0.06667px $primary,
      -2.24151px -32.05506px 0 -0.13333px $primary,
      -3.36582px -32.02361px 0 -0.2px $primary,
      -4.49065px -31.95265px 0 -0.26667px $primary,
      -5.61462px -31.84212px 0 -0.33333px $primary,
      -6.73634px -31.69198px 0 -0.4px $primary,
      -7.8544px -31.50227px 0 -0.46667px $primary,
      -8.9674px -31.27305px 0 -0.53333px $primary,
      -10.07395px -31.00444px 0 -0.6px $primary,
      -11.17266px -30.69663px 0 -0.66667px $primary,
      -12.26212px -30.34982px 0 -0.73333px $primary,
      -13.34096px -29.96429px 0 -0.8px $primary,
      -14.4078px -29.54036px 0 -0.86667px $primary,
      -15.46126px -29.07841px 0 -0.93333px $primary,
      -16.5px -28.57884px 0 -1px $primary,
      -17.52266px -28.04212px 0 -1.06667px $primary,
      -18.52792px -27.46878px 0 -1.13333px $primary,
      -19.51447px -26.85936px 0 -1.2px $primary,
      -20.48101px -26.21449px 0 -1.26667px $primary,
      -21.42625px -25.53481px 0 -1.33333px $primary,
      -22.34896px -24.82104px 0 -1.4px $primary,
      -23.2479px -24.07391px 0 -1.46667px $primary,
      -24.12186px -23.29421px 0 -1.53333px $primary,
      -24.96967px -22.48279px 0 -1.6px $primary,
      -25.79016px -21.64052px 0 -1.66667px $primary,
      -26.58223px -20.76831px 0 -1.73333px $primary,
      -27.34477px -19.86714px 0 -1.8px $primary,
      -28.07674px -18.938px 0 -1.86667px $primary,
      -28.7771px -17.98193px 0 -1.93333px $primary,
      -29.44486px -17px 0 -2px $primary,
      -30.07908px -15.99333px 0 -2.06667px $primary,
      -30.67884px -14.96307px 0 -2.13333px $primary,
      -31.24325px -13.91039px 0 -2.2px $primary,
      -31.7715px -12.83652px 0 -2.26667px $primary,
      -32.26278px -11.74269px 0 -2.33333px $primary,
      -32.71634px -10.63018px 0 -2.4px $primary,
      -33.13149px -9.5003px 0 -2.46667px $primary,
      -33.50755px -8.35437px 0 -2.53333px $primary,
      -33.84391px -7.19374px 0 -2.6px $primary,
      -34.14px -6.0198px 0 -2.66667px $primary,
      -34.39531px -4.83395px 0 -2.73333px $primary,
      -34.60936px -3.63759px 0 -2.8px $primary,
      -34.78173px -2.43218px 0 -2.86667px $primary,
      -34.91205px -1.21916px 0 -2.93333px $primary,
      -35px 0 0 -3px $primary,
      -35.04531px 1.22381px 0 -3.06667px $primary,
      -35.04775px 2.45078px 0 -3.13333px $primary,
      -35.00717px 3.6794px 0 -3.2px $primary,
      -34.92345px 4.90817px 0 -3.26667px $primary,
      -34.79654px 6.13557px 0 -3.33333px $primary,
      -34.62643px 7.36007px 0 -3.4px $primary,
      -34.41316px 8.58016px 0 -3.46667px $primary,
      -34.15683px 9.79431px 0 -3.53333px $primary,
      -33.85761px 11.001px 0 -3.6px $primary,
      -33.5157px 12.19872px 0 -3.66667px $primary,
      -33.13137px 13.38594px 0 -3.73333px $primary,
      -32.70493px 14.56117px 0 -3.8px $primary,
      -32.23675px 15.72291px 0 -3.86667px $primary,
      -31.72725px 16.86968px 0 -3.93333px $primary,
      -31.17691px 18px 0 -4px $primary,
      -30.58627px 19.11242px 0 -4.06667px $primary,
      -29.95589px 20.2055px 0 -4.13333px $primary,
      -29.28642px 21.27783px 0 -4.2px $primary,
      -28.57852px 22.32799px 0 -4.26667px $primary,
      -27.83295px 23.35462px 0 -4.33333px $primary,
      -27.05047px 24.35635px 0 -4.4px $primary,
      -26.23192px 25.33188px 0 -4.46667px $primary,
      -25.37819px 26.27988px 0 -4.53333px $primary,
      -24.49018px 27.1991px 0 -4.6px $primary,
      -23.56888px 28.0883px 0 -4.66667px $primary,
      -22.6153px 28.94626px 0 -4.73333px $primary,
      -21.6305px 29.77183px 0 -4.8px $primary,
      -20.61558px 30.56385px 0 -4.86667px $primary,
      -19.57168px 31.32124px 0 -4.93333px $primary,
      -18.5px 32.04294px 0 -5px $primary,
      -17.40175px 32.72792px 0 -5.06667px $primary,
      -16.27818px 33.37522px 0 -5.13333px $primary,
      -15.1306px 33.98389px 0 -5.2px $primary,
      -13.96034px 34.55305px 0 -5.26667px $primary,
      -12.76875px 35.08186px 0 -5.33333px $primary,
      -11.55724px 35.56951px 0 -5.4px $primary,
      -10.32721px 36.01527px 0 -5.46667px $primary,
      -9.08014px 36.41843px 0 -5.53333px $primary,
      -7.81748px 36.77835px 0 -5.6px $primary,
      -6.54075px 37.09443px 0 -5.66667px $primary,
      -5.25147px 37.36612px 0 -5.73333px $primary,
      -3.95118px 37.59293px 0 -5.8px $primary,
      -2.64145px 37.77443px 0 -5.86667px $primary,
      -1.32385px 37.91023px 0 -5.93333px $primary;
    display: inline-block;
    height: 8px;
    overflow: hidden;
    position: relative;
    text-indent: -9999px;
    width: 8px;
    @include transform-origin(50% 50%);
    @include rounded(100%);
  }
}
@keyframes loader {
  to {
    left: -100vw;
  }
}

@-webkit-keyframes whirly-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes whirly-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
