.custom-popover {
  --bs-popover-max-width: 12.5rem;
  --bs-popover-border-color: #{$primary};
  --bs-popover-header-bg: #{$primary};
  --bs-popover-header-color: #{$white};
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 0.5rem;
}
.popover {
  background-color: $white;
  border: 1px solid $default-border;
  box-shadow: $box-shadow;
  font-size: 0.8rem;
  border-radius: $border-radius;
  z-index: 999;
  .popover-header {
    background-color: $white;
    border-block-end: 1px solid $default-border;
  }
  .popover-body {
    color: $text-color;
  }
  &.only-body {
    border-radius: 0.3rem;
  }
}
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  border-top-color: $light;
}
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  border-right-color: $light;
}
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  border-left-color: $light;
}
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  border-bottom-color: $light;
}

@mixin pop-header-color($color) {
  .popover-header {
    background-color: $color;
    color: $white;
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
    border-bottom-color: $color;
  }
}
.header-primary {
  @include pop-header-color($primary);
}
.header-secondary {
  @include pop-header-color($secondary);
}
.header-warning {
  @include pop-header-color($warning);
}
.header-info {
  @include pop-header-color($info);
}
.header-success {
  @include pop-header-color($success);
}
.header-danger {
  @include pop-header-color($danger);
}
@mixin pop-bgcolor($color) {
  &.popover {
    border: 1px solid $color;
    .popover-header {
      background-color: $color;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      color: $white;
      border-radius: 0.3rem 0.3rem 0 0;
    }
    .popover-body {
      background-color: $color;
      color: $white;
      border-radius: 0 0 0.3rem 0.3rem;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: $color;
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: $color;
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: $color;
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: $color;
  }
}
.popover-primary {
  @include pop-bgcolor($primary);
}
.popover-secondary {
  @include pop-bgcolor($secondary);
}
.popover-warning {
  @include pop-bgcolor($warning);
}
.popover-info {
  @include pop-bgcolor($info);
}
.popover-success {
  @include pop-bgcolor($success);
}
.popover-danger {
  @include pop-bgcolor($danger);
}
.popover-purple {
  @include pop-bgcolor($purple);
}
.popover-teal {
  @include pop-bgcolor($teal);
}

.popover-primary-light {
  &.popover {
    border: 1px solid rgba($primary, 0.1);
    .popover-header {
      background-color: rgba($primary, 0.1);
      border-block-end: 1px solid rgba($primary, 0.1);
      color: $primary;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba($primary, 0.1);
      color: $primary;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba($primary, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba($primary, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba($primary, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba($primary, 0.1);
  }
}
.popover-secondary-light {
  &.popover {
    border: 1px solid rgba($secondary, 0.1);
    .popover-header {
      background-color: rgba($secondary, 0.1);
      border-block-end: 1px solid rgba($secondary, 0.1);
      color: $secondary;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba($secondary, 0.1);
      color: $secondary;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba(var(--secondary-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba($secondary, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba(var(--secondary-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba(var(--secondary-rgb), 0.1);
  }
}
.popover-warning-light {
  &.popover {
    border: 1px solid rgba($warning, 0.1);
    .popover-header {
      background-color: rgba($warning, 0.1);
      border-block-end: 1px solid rgba($warning, 0.1);
      color: $warning;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba($warning, 0.1);
      color: $warning;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba($warning, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba($warning, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba($warning, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba($warning, 0.1);
  }
}
.popover-info-light {
  &.popover {
    border: 1px solid rgba($info, 0.1);
    .popover-header {
      background-color: rgba($info, 0.1);
      border-block-end: 1px solid rgba($info, 0.1);
      color: $info;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba($info, 0.1);
      color: $info;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba($info, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba($info, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba($info, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba($info, 0.1);
  }
}
.popover-success-light {
  &.popover {
    border: 1px solid rgba($success, 0.1);
    .popover-header {
      background-color: rgba($success, 0.1);
      border-block-end: 1px solid rgba($success, 0.1);
      color: $success;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba($success, 0.1);
      color: $success;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba($success, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba($success, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba($success, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba($success, 0.1);
  }
}
.popover-danger-light {
  &.popover {
    border: 1px solid rgba($danger, 0.1);
    .popover-header {
      background-color: rgba($danger, 0.1);
      border-block-end: 1px solid rgba($danger, 0.1);
      color: $danger;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba($danger, 0.1);
      color: $danger;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba($danger, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba($danger, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba($danger, 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba($danger, 0.1);
  }
}
.popover-purple-light {
  &.popover {
    border: 1px solid rgba(var(--purple-rgb), 0.1);
    .popover-header {
      background-color: rgba(var(--purple-rgb), 0.1);
      border-block-end: 1px solid rgba(var(--purple-rgb), 0.1);
      color: $purple;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba(var(--purple-rgb), 0.1);
      color: $purple;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba(var(--purple-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba(var(--purple-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba(var(--purple-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba(var(--purple-rgb), 0.1);
  }
}
.popover-teal-light {
  &.popover {
    border: 1px solid rgba(var(--teal-rgb), 0.1);
    .popover-header {
      background-color: rgba(var(--teal-rgb), 0.1);
      border-block-end: 1px solid rgba(var(--teal-rgb), 0.1);
      color: $teal;
      border-radius: 0;
    }
    .popover-body {
      background-color: rgba(var(--teal-rgb), 0.1);
      color: $teal;
    }
  }
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after,
  &.bs-popover-top > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before,
  &.bs-popover-top > .popover-arrow::before {
    border-top-color: rgba(var(--teal-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before,
  &.bs-popover-end > .popover-arrow::before {
    border-right-color: rgba(var(--teal-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after,
  &.bs-popover-start > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before,
  &.bs-popover-start > .popover-arrow::before {
    border-left-color: rgba(var(--teal-rgb), 0.1);
  }
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before,
  &.bs-popover-bottom > .popover-arrow::before,
  &.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before,
  &.bs-popover-bottom .popover-header::before {
    border-bottom-color: rgba(var(--teal-rgb), 0.1);
  }
}
.timeline {
  position: relative;
  list-style: none;
  max-width: 1200px;
  @include margin-padding(0 auto, 20px 0 20px);
  &:before {
    content: ' ';
    position: absolute;
    width: 3px;
    margin-left: -1.5px;
    background-color: $gray-300;
    @include position(absolute, 0, null, 0, 50%);
  }
  > li {
    position: relative;
    margin-bottom: 20px;
    &::before,
    &::after {
      content: ' ';
      display: table;
    }
    > .timeline-panel {
      float: left;
      position: relative;
      width: 46%;
      padding: 20px;
      border: 1px solid $gray-500;
      @include rounded(0.25rem);
      @include box-shadow(null, 0, 1px, 6px, null, rgba(0, 0, 0, 0.05));
      &::before {
        content: ' ';
        display: inline-block;
        position: absolute;
        border-top: 8px solid transparent;
        border-right: 0 solid $gray-500;
        border-bottom: 8px solid transparent;
        border-left: 8px solid $gray-500;
        @include position(absolute, 26px, -8px, null, null);
      }
      &::after {
        content: ' ';
        display: inline-block;
        @include position(absolute, 27px, -7px, null, null);
        border-top: 7px solid transparent;
        border-right: 0 solid $white;
        border-bottom: 7px solid transparent;
        border-left: 7px solid $white;
      }
    }
  }
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-badge {
  z-index: 1;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: $white;
  overflow: hidden;
  @include rounded(50%);
  @include position(absolute, 16px, null, null, 50%);
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  &::before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0;
  }
  &::after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0;
  }
}
.timeline-badge.primary {
  background-color: $primary;
}
.timeline-badge.success {
  background-color: $success;
}
.timeline-badge.warning {
  background-color: $warning;
}
.timeline-badge.danger {
  background-color: $danger;
}
.timeline-badge.info {
  background-color: $info;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: regular;
}
.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}
.timeline-body > p + p {
  margin-top: 5px;
}
