.custom-select {
  > div:first-child {
    background-color: transparent !important;
    background-image: none !important;

    > div:not(:has(input)):not([id]) {
      // background-image: linear-gradient(178deg, rgb(6, 132, 20) -0.89%, rgb(4, 179, 66) 99.46%);
      // border-radius: 16px;
      // padding: 2px;
      // display: flex;

      // div {
      //   color: white;
      //   background-color: transparent;
      // }
    }
  }

  > div:not(.field):last-child {
    display: none;
  }
}

.overflow-unset {
  overflow: unset !important;
}
