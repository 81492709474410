:root {
  --tooltip-color: #333;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.tooltip-container {
  position: relative;
  display: block;
}

.tooltip {
  position: absolute;
  padding: 8px 12px;
  background-color: var(--tooltip-color);
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 999;
  outline: none;
  border: 1px solid var(--tooltip-color);
  z-index: 10000;
  cursor: pointer;
  opacity: 1 !important;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 10000;
}

.tooltip-arrow-top {
  bottom: -8px;
  border-width: 8px 8px 0 8px;
  border-color: var(--tooltip-color) transparent transparent transparent;
}

.tooltip-arrow-bottom {
  top: -8px;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent var(--tooltip-color) transparent;
}

.tooltip-arrow-left {
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent var(--tooltip-color);
}

.tooltip-arrow-right {
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 8px 8px 8px 0;
  border-color: transparent var(--tooltip-color) transparent transparent;
}

.tooltip-top {
  top: 0%;
  transform: translateX(0) translateY(-140%);
}

.tooltip-top::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  top: 100%;
  right: 0;
  left: 0;
}

.tooltip-bottom {
  bottom: 0%;
  transform: translateX(0) translateY(140%);
}

.tooltip-bottom::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 100%;
  right: 0;
  left: 0;
}

.tooltip-left {
  left: 0%;
  top: 50%;
  transform: translateX(-110%) translateY(-50%);
  z-index: 1;
}

.tooltip-left::before {
  content: '';
  position: absolute;
  width: 110%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.tooltip-right {
  right: 0%;
  top: 50%;
  transform: translateX(110%) translateY(-50%);
}

.tooltip-right::before {
  content: '';
  position: absolute;
  width: 110%;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
