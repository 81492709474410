#root-portal {
  .react-datepicker-popper {
    z-index: 9999;
  }
  .react-datepicker {
    border-radius: 20px;
    border: 2px solid #cccc;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__header > div:first-child {
    justify-content: space-between !important;

    button {
      font-size: 14px;
      padding: 8px;
      border: none;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #ccc;
      color: #333;
      cursor: pointer;
      &:hover {
        background-color: #e7e5e5;
      }
    }

    select {
      font-size: 14px;
      padding: 8px;
      border: none;
      border-radius: 5px;
      background-color: transparent;
      color: #333;
      cursor: pointer;
      &:hover {
        background-color: #e7e5e5;
      }
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: rgb(179, 247, 179);
    color: #333;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  [aria-selected='true'] {
    background-color: $primary;
    color: #fff;
  }
}
