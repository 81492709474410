.main-wrappers {
  background-color: $body-bg;
  &.pos-page {
    .sidebar {
      display: none;
      @include respond-below(custom991) {
        display: block;
      }
    }
    #toggle_btn {
      display: none;
    }
  }
}
.menu-opened {
  .pos-page {
    &.slide-nav {
      .sidebar {
        margin-left: 0;
      }
    }
    .sidebar {
      margin-left: -100%;
    }
  }
}
.product-view {
  width: 100%;
  border: 1px solid $gray-300;
  margin-bottom: 20px;
  &:hover {
    border: 1px solid $primary;
  }
  .product-viewimg {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    max-height: 120px;
    overflow: hidden;
    h6 {
      background: $success;
      color: $white;
      font-size: $font-size-8;
      font-weight: $font-weight-semibold;
      @include margin-padding(null, 3px);
      @include position(absolute, null, null, 0, 0);
    }
  }
  .product-viewcontent {
    @include margin-padding(null, 13px);
    h3 {
      font-size: $font-size-base;
      color: $black;
      font-weight: $font-weight-medium;
    }
    h4 {
      font-size: $font-size-12;
      color: $gray-600;
      font-weight: $font-weight-medium;
      margin-bottom: 5px;
    }
    h5 {
      font-size: $font-size-base;
      color: $gray-600;
      font-weight: $font-weight-medium;
    }
  }
}
.main-set {
  @include margin-padding(null, 0 30px);
  @include respond-below(custom991) {
    @include margin-padding(null, 0 10px);
  }
}
.text-underline {
  position: relative;
  &::after {
    content: '';
    background: $gray-600;
    width: 10px;
    height: 2px;
    @include position(absolute, null, null, 12px, 10px);
  }
}
.select-split {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-bottom: 20px;
  .select-group {
    width: calc(100% - 55px);
  }
  .btn-filters {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    @include margin-padding(0 0 0 15px, 0);
  }
  .select2-container--default {
    .select2-selection--single {
      background-color: $white;
      border: 1px solid $gray-300;
      height: 40px;
      @include rounded(4px);
      .select2-selection__rendered {
        line-height: 40px;
      }
      .select2-selection__arrow {
        height: 40px;
      }
    }
  }
}
.table {
  tr {
    &:hover {
      .productimg {
        .productcontet {
          h4 {
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.table-product {
  tr {
    th {
      font-weight: $font-weight-semibold;
      color: $gray-600;
      font-size: $font-size-base;
    }
    td {
      font-weight: $font-weight-semibold;
      color: $gray-600;
      font-size: $font-size-base;
    }
  }
}
.btn-totallabel {
  color: $white;
  background: $purple;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  @include rounded(5px);
  @include margin-padding(0 0 15px, 12px);
  h5 {
    font-size: $font-size-base;
    margin: 0;
    font-weight: $font-weight-semibold;
  }
  h6 {
    margin: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
  }
}
.setvaluecash {
  margin: 0 0 15px 0;
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    li {
      width: 33%;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
      a {
        border: 1px solid $gray-300;
        color: $black;
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
        min-height: 95px;
        @include rounded(5px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        -ms-flex-direction: column;
        flex-direction: column;
        @include margin-padding(null, 10px 20px);
        &:hover {
          border: 1px solid $purple;
          color: $purple;
        }
        img {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.setvalue {
  padding: 15px 0;
  ul {
    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      margin-bottom: 10px;
      h5 {
        font-size: $font-size-13;
        font-weight: $font-weight-medium;
      }
      h6 {
        font-size: $font-size-13;
        font-weight: $font-weight-medium;
      }
      &.total-value {
        h5 {
          font-size: $font-size-16;
          font-weight: $font-weight-semibold;
          color: $purple;
        }
        h6 {
          font-size: $font-size-16;
          font-weight: $font-weight-semibold;
          color: $purple;
        }
      }
    }
  }
}
.product-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border: 1px solid $body-bg;
  box-shadow: 0px 4px 4px 0px #ededed40;
  @include margin-padding(0 0 15px, 15px);
  @include respond-below(sm) {
    flex-direction: column;
    li {
      margin: 10px 0;
    }
  }
}
.increment-decrement {
  .input-groups {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    margin-top: 10px;
    input[type='button'] {
      background-color: transparent;
      font-size: $font-size-20;
      border: 1px solid $gray-500;
      border-radius: 4px;
      height: 20px;
      width: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      margin-top: 0px;
      line-height: 0;
      padding: 0;
      color: $gray-500;
    }
  }
  .quantity-field {
    width: 30px;
    text-align: center;
    background-color: transparent;
    float: left;
    border: 0;
  }
}
.productimg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  .productimgs {
    width: 82px;
    margin-right: 13px;
    img {
      width: 82px;
      height: 82px;
      object-fit: cover;
    }
  }
  .productcontet {
    h4 {
      font-size: $font-size-base;
      color: $black;
      font-weight: $font-weight-semibold;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      margin-bottom: 5px;
      img {
        opacity: 0;
        margin-top: 5px;
        display: block;
      }
    }
  }
  .productlinkset {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    h5 {
      background: $primary;
      color: $white;
      font-size: $font-size-12;
      @include rounded(5px);
      margin-right: 10px;
      @include margin-padding(null, 2px 10px);
    }
    a {
      position: relative;
      top: 2px;
    }
  }
}
.product-table {
  height: 34vh;
  overflow: auto;
  @include respond-below(custom991) {
    height: auto;
  }
}
.btn-set {
  @include margin-padding(15px 0 25px, null);
  h4 {
    font-weight: $font-weight-bold;
  }
}
.product-detils {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  @include respond-below(custom991) {
    height: auto;
  }
}
.tab-set {
  margin-bottom: 20px;
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      width: 48%;
      text-align: center;
      margin-right: 5px;
      @include respond-below(custom575) {
        width: 100%;
        margin-right: 0;
      }
      &:last-child {
        margin: 0;
        width: 50%;
        @include respond-below(custom575) {
          width: 100%;
        }
      }
      a {
        background: $gray-700;
        width: 100%;
        display: block;
        color: $white;
        font-weight: $font-weight-semibold;
        @include rounded(5px);
        @include margin-padding(null, 10px);
        @include respond-below(custom575) {
          @include rounded(0);
        }
      }
      &.active {
        a {
          background: $primary;
        }
      }
    }
  }
}
#delete {
  .modal-body {
    .btn {
      min-width: 100px;
      @include margin-padding(null, 10px);
    }
  }
}
.owl-product {
  padding-top: 35px;
  .owl-nav {
    margin-top: 10px;
    @include position(absolute, -10px, 0, null, null);
    button {
      border-radius: 50px !important;
      width: 20px;
      height: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      width: 19px;
      position: relative;
      height: 19px;
      background: $white !important;
      color: $purple !important;
      span {
        font-size: 0;
      }
      &.owl-next {
        span:before {
          @include position(absolute, 2px, 5px, null, null);
          content: '\f054';
          font-family: 'Font Awesome 5 Free';
          font-style: normal;
          font-weight: 900;
          font-size: 10px;
          color: #1a54c7;
        }
      }
      &.owl-prev {
        span:before {
          content: '\f053';
          font-family: 'Font Awesome 5 Free';
          font-style: normal;
          font-weight: 900;
          font-size: 10px;
          color: #1a54c7;
          @include position(absolute, 2px, 7px, null, null);
        }
      }
      &:hover {
        background: #1a54c7 !important;
        span:before {
          color: $white;
        }
      }
    }
  }
}
.actionproduct {
  text-align: right;
  margin-bottom: 15px;
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: flex-end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    li {
      margin-left: 10px;
    }
  }
}
.btn-pos {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      margin-right: 1px;
      margin-bottom: 10px;
      @include respond-below(custom991) {
        width: 48%;
      }
      @include respond-below(custom575) {
        width: 100%;
        margin-right: 10px;
      }
      a {
        &.btn {
          color: #888b94;
          font-size: 10px;
          width: 100%;
          padding: 5px 12px;
          background: $gray-300;
          border-radius: 50px;
          border: 1px solid $gray-300;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          @include margin-padding(null, 5px 12px);
          @include respond-below(custom991) {
            min-width: auto;
            @include margin-padding(null, 6px 15px);
          }
          &:hover {
            border: 1px solid #ea5455;
            color: #ea5455;
            background: rgba(234, 84, 85, 0.06);
          }
        }
        &.btn-hold {
          background: $warning;
        }
        &.btn-quation {
          background: $gray-600;
        }
        &.btn-void {
          background: #d0512e;
        }
        &.btn-payment {
          background: $success;
        }
        &.btn-recent {
          background: $primary;
        }
      }
    }
  }
}
.calculator-set {
  .calculatortotal {
    margin-bottom: 15px;
    h4 {
      width: 100%;
      background: $primary;
      text-align: center;
      color: $white;
      font-size: $font-size-36;
      font-weight: $font-weight-bold;
      @include rounded(5px);
    }
  }
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      width: 33.3%;
      text-align: center;
      a {
        width: 100%;
        border: 1px solid $gray-500;
        font-size: $font-size-24;
        font-weight: $font-weight-medium;
        display: block;
        color: $black;
        @include rounded(0);
        @include margin-padding(null, 10px);
        &.btn-closes {
          background: $danger-700;
        }
        &.btn-reverse {
          background: #2e7d32;
        }
        &:hover {
          background: $gray-100;
        }
      }
    }
  }
}
.hold-order {
  text-align: center;
  @include margin-padding(15px 0, null);
  h2 {
    @include margin-padding(null, 0 0 30px);
    color: $primary;
    font-size: $font-size-36;
    font-weight: $font-weight-bold;
  }
}
.para-set {
  margin-bottom: 30px;
  p {
    color: $gray-700;
    font-size: $font-size-13;
    font-weight: $font-weight-medium;
  }
}
.delete-order {
  text-align: center;
}
.btn-set {
  h4 {
    background: $primary;
    text-align: center;
    color: $white;
    @include rounded(5px);
    @include margin-padding(null, 10px);
  }
}
.header-menuset {
  @include margin-padding(null, 15px);
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      width: 50%;
      margin-bottom: 10px;
      @include respond-below(custom991) {
        width: 100%;
      }
      .win-maximize {
        display: none;
      }
      a {
        border: 1px solid $primary;
        width: 95%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        background: $primary;
        color: $white;
        @include margin-padding(null, 10px);
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
.invoice-load-btn {
  text-align: center;
  margin-top: 20px;
  .btn {
    font-weight: $font-weight-bold;
    font-size: $font-size-16;
    color: $primary;
    min-width: 150px;
    background: $white;
    border: 2px solid $primary;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    @include rounded(6px);
    @include margin-padding(null, 12px 15px);
    span {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 8px;
      vertical-align: 0;
      border: 3px solid #fc88037d;
      border-right-color: $primary;
      animation: 0.75s linear infinite spinner-border;
      @include rounded(50%);
    }
    &:hover {
      background: $primary;
      color: $white;
      span {
        border: 3px solid $white;
        border-right-color: $white;
      }
    }
  }
}
.form-new {
  .input-blocks {
    input {
      height: 50px;
      background: $light;
    }
    .scanner-set {
      min-width: 50px;
      @include position(relative, null, null, null, -2px);
    }
  }
}
.product-details {
  background: $white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #ededed40;
  border: 1px solid #f8f8f8 !important;
  height: 105px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  flex-direction: column;
  &:hover {
    background: $purple;
    h6 {
      color: $white;
    }
  }
  &.active {
    background-color: $purple !important;
    h6 {
      color: $white;
    }
  }
  img {
    width: auto !important;
  }
  h6 {
    font-size: $font-size-13;
    color: $black;
    margin-top: 10px;
  }
}
.btn-scanner-set {
  background: $purple;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  max-width: 160px;
  margin-left: auto;
  color: $white;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  &:hover {
    background: $primary;
    color: $white;
  }
}
.split-card {
  margin: 0 0 15px;
  border: 1px dashed $body-bg;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    background: $body-bg;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    top: -10px;
    left: -10px;
  }
  &::after {
    position: absolute;
    content: '';
    background: $body-bg;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    top: -10px;
    right: -10px;
  }
}
.totalitem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 15px;
  h4 {
    padding: 7px 13px;
    background: #f8f8f8;
    color: $purple;
    font-size: 12px;
    font-weight: $font-weight-bold;
    border-radius: 50px;
  }
  a {
    font-size: $font-size-base;
    color: #ea5455;
    font-weight: $font-weight-bold;
  }
}
.card {
  &.card-order {
    border: 0;
  }
}
.btn-adds {
  border: 2px solid $success;
  color: $success;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-weight: $font-weight-semibold;
  margin-bottom: 20px;
  &:hover {
    background: $success;
    color: $white;
  }
}
.order-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 25px;
  .orderid {
    h4 {
      font-size: $font-size-18;
      font-weight: $font-weight-bold;
      color: #5e5873;
      margin-bottom: 0;
    }
    h5 {
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      color: $black;
    }
  }
  .actionproducts {
    > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      > li {
        margin-left: 15px;
        .deletebg {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          justify-content: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          background: $white;
          box-shadow: 0px 4px 14px 0px #c7c7c740;
          width: 30px;
          height: 30px;
          border-radius: 5px;
        }
      }
    }
  }
}
.tabs_wrapper {
  .owl-product {
    margin-bottom: 25px;
  }
}
.productset {
  border: 1px solid #f8f8f8;
  box-shadow: 0px 4px 4px 0px #ededed40;
  background: $white;
  margin: 0 0 25px;
  border-radius: 5px;
  position: relative;
  width: 100%;
  overflow: hidden;
  &:hover {
    border: 1px solid $purple;
  }
  .check-product {
    display: none;
    width: 34px;
    height: 23px;
    color: $white;
    position: absolute;
    left: 0;
    top: 0;
    background: $purple;
    border-radius: 4px 0;
  }
  &.active {
    border: 1px solid $purple;
    .check-product {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
    }
  }
  &:hover {
    .productsetimg {
      img {
        transform: scale(1.2);
      }
      h6 {
        transform: translatey(0);
      }
    }
  }
  .productsetimg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;
    overflow: hidden;
    img {
      transition: all 0.5s;
      border-radius: 5px 5px 0px 0px;
      @include respond-below(custom991) {
        max-height: 150px;
      }
    }
    h6 {
      position: absolute;
      background: $primary;
      color: $white;
      font-size: 10px;
      padding: 5px;
      border-radius: 5px;
      top: 20px;
      right: 20px;
      transform: translatey(-60px);
      transition: all 0.5s;
    }
  }
  .productsetcontent {
    padding: 13px;
    text-align: center;
    h4 {
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      color: $black;
      margin-bottom: 13px;
    }
    h5 {
      color: #6e6b7b;
      font-size: 12px;
    }
    h6 {
      color: #ea5455;
      margin: 0 0 0 auto;
      font-weight: $font-weight-semibold;
      font-size: $font-size-base;
    }
  }
}
.tabs_wrapper {
  ul.tabs {
    display: inline-block;
    width: 100%;
    padding-left: 0;
    li {
      list-style: none;
      cursor: pointer;
      display: inline-block;
      border-radius: 5px;
      color: white;
      font-family: sans-serif;
      width: 100%;
      &.active {
        background-color: $primary;
        border-color: $primary;
        h6 {
          a {
            color: $white;
          }
        }
        .product-details {
          background: transparent;
          border: 0 !important;
          h6 {
            color: $white;
          }
        }
      }
    }
  }
  .tabs_container {
    padding-top: 00px;
    display: inline-block;
    width: 100%;
    .tab_content {
      display: none;
      width: 100%;
      &.active {
        display: inline-block;
      }
    }
  }
}

.slider-active {
  border: 1px yellow solid;
}

.scrollable-container {
  max-height: 62vh;
  overflow-y: auto;
}

.pos-slick-item {
  padding: 16px 0;
  cursor: pointer;
}

.pos-slick-item.selected {
  border: 2px solid #697565;
  border-radius: 5px;
}
