@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: #16a730;
    --secondary: #fff;
    --primary-text: #fff;
    --primary-button: #0b701d;
    --primary-light: #e5fce9;
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
