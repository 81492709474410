.select2-container {
  min-width: 100% !important;
  z-index: 99;
  .select2-selection--single {
    height: 40px;
  }
}
.select2-container--default {
  .select2-selection--single {
    border: 1px solid var(--Stroke, rgba(145, 158, 171, 0.3));
    @include rounded(5px);
    .select2-selection__rendered {
      color: $text-color;
      line-height: 38px;
      @include rounded(5px);
      padding-left: 10px;
      font-size: $font-size-14;
      font-weight: $font-weight-normal;
    }
    .select {
      width: 219px;
    }
    .select2-selection__arrow {
      height: 40px;
      right: 6px;
    }
  }
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  @include position(absolute, 50%, null, null, 50%);
  border-color: #637381;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  margin-left: -6px;
  margin-top: -3px;
  width: 0;
  @include transform(rotate(45deg) translateY(-50%));
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: $black;
  border-width: 2px 0 0 2px;
  margin-top: 3px;
  @include margin-padding(null, 3px);
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: $primary;
  color: $white;
}
.select2-container--focus {
  .select2-selection--single {
    background: $white !important;
    border-color: $primary;
  }
}
span.select2-container.select2-container--default.select2-container--open {
  z-index: 9999;
}
.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple,
.select2-container--classic .select2-selection--single .select2-selection__arrow,
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  border-color: $border-color;
  color: $text-color;
  height: 40px;
  line-height: 40px;
}

.select2-container--default .select2-selection--multiple {
  line-height: 27px;
  height: auto;
  min-height: 40px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: $info;
  border-color: $info;
  color: $white;
}
