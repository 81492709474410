$active-bg-color: rgba(17, 165, 43, 1);
$default-bg-color: rgba(17, 165, 43, 0.3);
$transition-duration: 0.2s;
$box-width: 6.86px;
$box-height: 28.8px;
$active-box-height: 48px;

$small-box-height: 14px;
$small-box-width: 4px;
$small-active-box-height: 24px;

.loading-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: $active-box-height;
  margin: auto;
  width: fit-content;
  gap: 8px;
}

.loading-item {
  border-radius: 10px;
  width: $box-width;
  height: $box-height;
  background-color:$primary;
  opacity: 0.2;
  transition:
    height $transition-duration,
    background-color $transition-duration;

  &.active {
    height: $active-box-height;
    background-color: $primary;
    opacity: 0.8;
  }
}

.loading-container-small {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: $small-active-box-height;
  margin: auto;
  width: fit-content;
  gap: 4px;
}

.loading-item-small {
  border-radius: 8px;
  width: $small-box-width;
  height: $small-box-height;
  background-color:$primary;
  opacity: 0.2;
  transition:
    height $transition-duration,
    background-color $transition-duration;

  &.active {
    height: $small-active-box-height;
    background-color: $primary;
    opacity: 0.8;
  }
}
