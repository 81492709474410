.custom-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease;
}

.custom-checkbox:checked {
  border-color: #16a730; /* Updated to new primary color */
  background-color: #16a730; /* Updated to new primary color */
}

.custom-checkbox:checked::before {
  content: '\2714'; /* Unicode character for checkmark */
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-checkbox-label {
  cursor: pointer;
  margin-left: 8px;
}
